import styled from 'styled-components';
import { ProfileMenuCommonWrapper } from '../../styles';
import { Scrollbar } from '@veneer/core';
import primitives from '@veneer/primitives';

export const ContainerWithScrollbar = styled(Scrollbar)`
  overflow-y: auto;
  max-height: 404px;
`;

export const Container = styled(ProfileMenuCommonWrapper)`
  // Hide scrollbar on IE and Edge
  -ms-overflow-style: none;
  // Hide scrollbar on Firefox
  scrollbar-width: none;
  // Hide scrollbar for Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  .vn-modal--content {
    width: 520px !important;
    min-width: 520px !important;
  }
`;

export const CreateDomainModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${primitives.layout.size8}px;
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const ToastAction = styled.button`
  cursor: pointer;
  padding-top: ${primitives.layout.size2}px;
  color: #3ab4eb;
  border: none;
  outline: none;
  background: none;
  font-size: ${primitives.typography.size2};
`;
