import { isInternetExplorer } from 'src/utils/isInternetExplorer';
import styled from 'styled-components';
import { Card } from '@veneer/core';
import primitives from '@veneer/primitives';
import { isRTLType } from 'src/types/commonPropertiesType';

export const ProfileMenuCommonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${() =>
    isInternetExplorer() && `padding-bottom: ${primitives.layout.size5}px;`}
`;

export const ModalContentWrapper = styled(ProfileMenuCommonWrapper)`
  & > * {
    ${() =>
      isInternetExplorer() && `padding-top: ${primitives.layout.size2}px;`}
  }
  & > *:not(:last-child) {
    border-bottom: solid 1px rgba(33, 33, 33, 0.1);
    padding-bottom: 8px;
  }
`;

export const UserViewWrapper = styled(ProfileMenuCommonWrapper)``;

export const LogoutWrapper = styled(ProfileMenuCommonWrapper)``;

export const Container = styled.div`
  position: relative;
`;

const paddingSize = 16;

export const MenuModal = styled(Card)<isRTLType>`
  position: absolute;
  z-index: 99;
  top: calc(100% + 7px);

  padding: ${paddingSize}px;
  width: ${320 - 2 * paddingSize}px;
  overflow: hidden;

  li {
    list-style: none;
  }

  ${({ isRTL }) => {
    if (isRTL) return `left: ${primitives.layout.size1}px;`;
    return `right: ${primitives.layout.size1}px;`;
  }}
`;
