import { PublishCdmEventsOptions } from '@jarvis/shell-commons/dist/services/AnalyticsService/types';

const SIMPLE_UI_VERSION = '1.5.0';
const EVENTING_EVENT_VERSION = '1.4.0';

export const ACTIONS = {
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  CONTROL_HYPERLINK_CLICKED: 'ControlHyperLinkClicked',
  CONTROL_ACCORDIAN_EXPANDED: 'ControlAccordianExpanded',
  CONTROL_ACCORDIAN_COLLAPSED: 'ControlAccordianCollapsed'
};

const EVENT_DETAIL_DEFAULT_VALUES = {
  activity: 'ConsumerPortal-v01',
  version: SIMPLE_UI_VERSION,
  screenPath: '/ReactConsumerLayout/'
};

type EventType = {
  activity: string;
  version: string;
  screenPath: string;
  screenName: string;
  action: string;
  controlName?: string;
  controlDetail?: string;
};

// Event call

const publishEvent = (event: EventType, options?: PublishCdmEventsOptions) => {
  const publishCdmEvents = window.Shell?.v1?.analytics?.publishCdmEvents;

  if (!publishCdmEvents) {
    return;
  }

  const events = Array.isArray(event) ? event : [event];

  const cdmEvents = events.map((item) => ({
    dateTime: new Date().toISOString(),
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    eventDetail: item,
    version: EVENTING_EVENT_VERSION
  }));

  publishCdmEvents(cdmEvents, options);
};

export const triggerAvatarIconEvent = () => {
  publishEvent({
    ...EVENT_DETAIL_DEFAULT_VALUES,
    screenName: 'PortalHeader',
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    controlName: 'AvatarIcon'
  });
};

export const triggerAccountProfileEvent = () => {
  publishEvent({
    ...EVENT_DETAIL_DEFAULT_VALUES,
    screenName: 'ProfileCard',
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    controlName: 'AccountProfile',
    controlDetail: '/account-details/profile'
  });
};

export const triggerMenuItemClickEvent = (
  action: string = ACTIONS.CONTROL_HYPERLINK_CLICKED,
  controlName: string,
  controlDetail: string
) => {
  publishEvent({
    ...EVENT_DETAIL_DEFAULT_VALUES,
    screenName: 'SideMenu',
    action,
    controlName,
    ...(controlDetail && { controlDetail })
  });
};
