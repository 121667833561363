import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const Container = styled.p`
  border-radius: ${primitives.layout.cornerRadius1}px;
  padding: 4px 8px 3px;
  background: ${primitives.color.royalBlue7};
  text-align: center;
  color: ${primitives.color.white};
  text-transform: lowercase;
  font-weight: 500;
  font-size: ${primitives.typography.size1};
  line-height: ${primitives.typography.lineHeight2};
  display: flex;
`;
