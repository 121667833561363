import { isInternetExplorer } from 'src/utils/isInternetExplorer';
import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const Container = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin-right: 0px !important;
  ${() =>
    isInternetExplorer() &&
    `margin-right: ${primitives.layout.size3}px !important;`}
`;
