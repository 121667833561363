import React from 'react';
import createId from '../../utils/createId';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import useAutoScrollUpOnRouteChange from '../../hooks/useAutoScrollUpOnRouteChange';
import WidgetListFactory from 'src/components/factories/WidgetListFactory';
import LayoutContentFactory from 'src/components/factories/LayoutContentFactory';
import * as S from './styles';
import * as T from './types';
import { useRootContext } from 'src/contexts/Root';

const containerId = createId('layout-scrollable-container');
const headerHeight = '68px';
const footerHeight = '76px';

const PageLayout: T.RegularLayoutType = ({ properties, children }) => {
  const { header, footer, content, customStyle } = properties;
  const rootProperties = useRootContext();

  useAutoScrollUpOnRouteChange({ containerId });

  const hasLogoWidget = header?.leftContent?.widgetList.filter(
    (widget) => widget.logo?.enable
  )[0];
  const linkUrl = hasLogoWidget
    ? header?.leftContent?.widgetList[0]?.logo.logoReference // mudei
    : '';

  const LinkWrapper = ({ children }: { children: React.ReactNode }) =>
    linkUrl ? <a href={linkUrl}>{children}</a> : <>{children}</>;

  return (
    <S.Container customStyles={customStyle}>
      {header?.enable && (
        <Header
          data-testid={createId('header')}
          headerHeight={headerHeight}
          asset={header?.asset}
          customStyle={header?.customStyle}
          leftContent={
            <LinkWrapper>
              <WidgetListFactory
                data-testid={createId('header_left_content')}
                widgetList={header?.leftContent?.widgetList}
              />
            </LinkWrapper>
          }
          rightContent={
            <WidgetListFactory
              data-testid={createId('header_right_content')}
              widgetList={header?.rightContent?.widgetList}
            />
          }
        />
      )}
      <S.Content
        data-testid={createId(containerId)}
        id={containerId}
      >
        <LayoutContentFactory
          {...content}
          rootProperties={rootProperties}
        >
          {(props) =>
            typeof children === 'function'
              ? children({
                  containerId: containerId,
                  ...props
                })
              : children
          }
        </LayoutContentFactory>
        {footer?.enable && (
          <Footer
            data-testid={createId('footer')}
            asset={footer?.asset}
            leftContent={
              <WidgetListFactory
                data-testid={createId('footer_left_content')}
                widgetList={footer?.leftContent?.widgetList}
              />
            }
            rightContent={
              <WidgetListFactory
                data-testid={createId('footer_right_content')}
                widgetList={footer?.rightContent?.widgetList}
              />
            }
            height={footerHeight}
          />
        )}
      </S.Content>
    </S.Container>
  );
};

export default PageLayout;
