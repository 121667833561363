import React, { useState } from 'react';
import LazyComponentFactory from 'src/components/factories/LazyComponentFactory';
import * as T from './types';
import * as S from './styles';
import useLegacyOnboardingSidebar from './useLegacyOnboardingSidebar';

const Sidebar: T.SidebarType = ({
  asset,
  customStyle,
  defaultVisible,
  rootProperties,
  useLegacyOnboardingSidebar: useLegacyOnboardingSidebarProp,
  children
}) => {
  const [sidebarVisibility, setSidebarVisibility] =
    useState<boolean>(!!defaultVisible);
  const [sidebarContext, setSidebarContext] = useState<unknown>();

  const legacyAdapter = useLegacyOnboardingSidebar({
    enable: !!useLegacyOnboardingSidebarProp,
    setSidebarVisibility,
    sidebarVisibility
  });

  const sidebarChildrenProps = {
    sidebarVisibility: legacyAdapter?.sidebarVisibility,
    setSidebarVisibility: legacyAdapter?.setSidebarVisibility,
    sidebarContext,
    setSidebarContext
  };

  return (
    <S.Container
      fullWidth={!!customStyle?.fullWidth}
      data-testid={'jshell-header-test'}
    >
      {sidebarChildrenProps?.sidebarVisibility && (
        <S.LeftContent>
          <LazyComponentFactory
            rootProperties={rootProperties}
            assetReference={asset?.assetReference}
            properties={{ ...sidebarChildrenProps, ...asset?.properties }}
            defaultAppearance="linear"
          />
        </S.LeftContent>
      )}
      <S.RightContent>
        {typeof children === 'function'
          ? children({ ...sidebarChildrenProps })
          : children}
      </S.RightContent>
    </S.Container>
  );
};

export default Sidebar;
