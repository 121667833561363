import { isInternetExplorer } from 'src/utils/isInternetExplorer';
import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${primitives.layout.size5}px;

  ${() =>
    isInternetExplorer() &&
    `& > *:not(:first-child) {
      margin-left: ${primitives.layout.size2}px;
    }
    & > *:not(:last-child) {
      margin-right: ${primitives.layout.size2}px;
    }`}
`;
