import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: ${primitives.layout.size3}px;
  flex-wrap: nowrap;
  padding: ${primitives.layout.size3}px ${primitives.layout.size0}px;
`;
