import primitives from '@veneer/primitives';

export const DEFAULT_HEADER_HEIGHT = '80px';
export const DEFAULT_FOOTER_HEIGHT = '64px';
export const DEFAULT_RESPONSIVE_BREAKPOINT = `${primitives.layout.breakpoint6}px`;
export const DEFAULT_SIZE = 'large';

export const SHELL_STATES = {
  gateway: 'gateway',
  nonGateway: 'non-gateway'
};
