import styled from 'styled-components';
import primitives from '@veneer/primitives';
import * as T from './types';
import { themeModeType } from 'src/types/commonPropertiesType';
import { getColor, getFontSize } from 'src/components/factories/utils/styles';

type CustomProperties = {
  customStyle: T.InformationWidgetPropsType['customStyle'];
};

export const Text = styled.p<CustomProperties & themeModeType>`
  margin: ${primitives.layout.size0}px ${primitives.layout.size5}px;
  font-size: ${({ customStyle }) => getFontSize(customStyle?.fontSize)};
  color: ${({ customStyle, themeMode }) =>
    getColor({
      defaultColor: primitives.color.gray5,
      defaultHighContrastColor: primitives.color.highContrastGray,
      themeMode: themeMode,
      customColor: customStyle?.color
    })};
`;

export const AnchorText = styled.a<CustomProperties & themeModeType>`
  margin: ${primitives.layout.size0}px ${primitives.layout.size5}px;
  font-size: ${({ customStyle }) => getFontSize(customStyle?.fontSize)};
  color: ${({ customStyle, themeMode }) =>
    getColor({
      defaultColor: primitives.color.hpBlue5,
      defaultHighContrastColor: primitives.color.highContrastYellow,
      themeMode: themeMode,
      customColor: customStyle?.color
    })};
`;
