import React from 'react';
import Sidebar from './components/Sidebar';
import * as T from './types';

const LayoutContentFactory: T.LayoutContentFactoryType = ({
  children,
  rootProperties,
  sidebar
}) => {
  if (sidebar?.enable) {
    return (
      <Sidebar
        rootProperties={rootProperties}
        {...sidebar}
      >
        {children}
      </Sidebar>
    );
  }

  return children({ ...rootProperties });
};

export default LayoutContentFactory;
