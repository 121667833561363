import React from 'react';
import { useRootContext } from 'src/contexts/Root';

export default function useLegacyOnboardingSidebar({
  enable,
  setSidebarVisibility: setSidebarVisibilityProp,
  sidebarVisibility: sidebarVisibilityProp
}: {
  enable: boolean;
  sidebarVisibility: boolean;
  setSidebarVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { store } = useRootContext();
  const { sideBar, setSidebarVisibility: setSidebarVisibilityLegacy } =
    store.useReactStore(React)?.state?.onboarding || {};

  const sidebarVisibility = enable
    ? !!sideBar?.enabled
    : !!sidebarVisibilityProp;

  function setSidebarVisibility(
    state: boolean | ((previousState: boolean) => boolean)
  ) {
    const newValue =
      typeof state === 'function' ? state?.(sidebarVisibility) : state;

    const setter = enable
      ? setSidebarVisibilityLegacy
      : setSidebarVisibilityProp;

    setter(newValue);
  }

  return {
    sidebarVisibility,
    setSidebarVisibility
  };
}
