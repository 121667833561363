import React, { useMemo } from 'react';
import { Button } from '@veneer/core';
import createId from 'src/utils/createId';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import useAutoScrollUpOnRouteChange from 'src/hooks/useAutoScrollUpOnRouteChange';
import HeaderLeadingArea from './components/HeaderSideMenuLeadingArea';
import WidgetListFactory from 'src/components/factories/WidgetListFactory';
import { SHELL_STATES } from './../../configs/constants';
import * as S from './styles';
import * as T from './types';
import * as Constants from './../../configs/constants';
import { useRootContext } from 'src/contexts/Root';
import { useDirectionContext } from 'src/contexts/Direction';
import { useThemeContext } from 'src/contexts/Theme';
import { dispatchEvent } from 'src/utils/jweb/EventService';

const containerId = createId('layout-scrollable-container');

const SideMenuLayout: T.SideMenuLayoutType = ({ properties, children }) => {
  const { header, footer, customStyle } = properties;
  const rootProperties = useRootContext();
  const { isRTL } = useDirectionContext();
  const { mode } = useThemeContext();
  const headerHeight = Constants.DEFAULT_HEADER_HEIGHT;
  const footerHeight = Constants.DEFAULT_FOOTER_HEIGHT;
  const { theme, platform, globalTranslate } = rootProperties || {};
  const responsivelyProps = useMemo(
    () => theme?.getScreenResponsivelyProperties() || {},
    [theme]
  );
  const {
    responsiveBreakpoint = Constants.DEFAULT_RESPONSIVE_BREAKPOINT,
    size = Constants.DEFAULT_SIZE
  } = responsivelyProps;
  const logoColor = mode === 'contrast' ? 'white' : null;
  const iconsOnly = header?.leftMenuArea?.iconsOnly?.enable;
  useAutoScrollUpOnRouteChange({ containerId });
  const footerRightAreaWidgetsFromProps = footer?.rightContent?.widgetList;

  const openedFromDeleteAccount =
    window.Shell.v1.navigation.location.search.includes(
      `?shellState=${SHELL_STATES.nonGateway}`
    );

  header.leftMenuArea.logo.color = logoColor;

  const translatedCloseText = useMemo(
    () =>
      globalTranslate(
        header.rightContent.closeButton?.translationKey,
        header.rightContent.closeButton?.value
      ),
    [
      globalTranslate,
      header.rightContent.closeButton?.translationKey,
      header.rightContent.closeButton?.value
    ]
  );

  const headerRightArea = () => {
    if (
      platform === 'ios' ||
      platform === 'android' ||
      (openedFromDeleteAccount && platform === 'mac')
    ) {
      return (
        <Button
          onClick={() => {
            dispatchEvent('jarvisEventFinished');
            dispatchEvent('Close');
          }}
          appearance="ghost"
          data-testid="home-close-button"
        >
          {translatedCloseText}
        </Button>
      );
    }

    if (platform === 'windows' || platform === 'mac') {
      return <></>;
    }

    return (
      <WidgetListFactory
        data-testid={createId('WidgetListFactory')}
        widgetList={header?.rightContent?.widgetList}
      />
    );
  };

  const footerRightAreaWidgets = useMemo(() => {
    return footerRightAreaWidgetsFromProps?.map?.((widget) => {
      if (
        widget.information?.enable &&
        !widget.information?.customStyle?.fontSize
      ) {
        return {
          ...widget,
          information: {
            ...widget.information,
            customStyle: {
              ...widget?.information?.customStyle,
              fontSize: 14
            }
          }
        };
      }
      return widget;
    });
  }, [footerRightAreaWidgetsFromProps]);

  return (
    <S.Container
      data-testid={createId('container')}
      responsiveBreakpoint={responsiveBreakpoint}
      customStyles={customStyle}
      size={size}
      isRTL={isRTL}
      iconsOnly={iconsOnly}
    >
      <Header
        data-testid={createId('header')}
        headerHeight={headerHeight}
        customStyle={header?.customStyle}
        leftContent={
          <HeaderLeadingArea
            data-testid={createId('HeaderLeadingArea')}
            {...{
              headerHeight,
              ...header?.leftMenuArea
            }}
          />
        }
        rightContent={headerRightArea()}
        isWindowsApp={platform === 'windows'}
      />
      <S.Content
        data-testid={createId(containerId)}
        id={containerId}
      >
        <div>
          {typeof children === 'function'
            ? children({
                ...rootProperties,
                containerId: containerId
              })
            : children}
        </div>
        {footer?.enable && (
          <Footer
            data-testid={createId('footer')}
            asset={footer?.asset}
            leftContent={
              <WidgetListFactory
                data-testid={createId('footer_left_content')}
                widgetList={footer?.leftContent?.widgetList}
              />
            }
            rightContent={
              <WidgetListFactory
                data-testid={createId('footer_right_content')}
                widgetList={footerRightAreaWidgets}
              />
            }
            transparentBackground={true}
            height={footerHeight}
          />
        )}
      </S.Content>
    </S.Container>
  );
};

export default SideMenuLayout;
