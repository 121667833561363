import React, { useMemo, useState } from 'react';
import Breadcrumbs from '../Breadcrumbs';
import RecursiveMenuItemLoader from '../RecursiveMenuItem';
import WidgetFactory from 'src/components/factories/WidgetFactory';
import createId from 'src/utils/createId';
import * as T from './types';
import * as S from './styles';
import * as Constants from 'src/configs/constants';
import { useRootContext } from 'src/contexts/Root';
import { useDirectionContext } from 'src/contexts/Direction';
import { RouteType } from '@jarvis/shell-commons/dist/interface/v1/routes/types';

type MenuType = T.HeaderLeadingAreaPropsType['menuList'][number];

const HeaderLeadingArea: T.HeaderLeadingAreaType = ({
  breadcrumb,
  headerHeight,
  menuList,
  widgetList,
  iconsOnly,
  customStyle,
  logo,
  image,
  appName
}) => {
  const { routes, breadcrumbs: breadcrumbsInterface, theme } = useRootContext();
  const { isRTL } = useDirectionContext();
  const [isIconReferenceNotEmpty, setIsIconReferenceNotEmpty] = useState(false);

  const currentAppearance = customStyle?.appearance;

  const responsivelyProps = useMemo(
    () => theme?.getScreenResponsivelyProperties() || {},
    [theme]
  );
  const {
    responsiveBreakpoint = Constants.DEFAULT_RESPONSIVE_BREAKPOINT,
    size = iconsOnly?.enable ? 'small' : Constants.DEFAULT_SIZE
  } = responsivelyProps;

  const hasWidgets = !!widgetList?.length;
  const iconsOnlyDisabled = !iconsOnly?.enable;

  const isBreadcrumbEnabled = useMemo(() => {
    if (typeof breadcrumb?.enable === 'boolean') return breadcrumb?.enable;
    return !!breadcrumbsInterface?.isEnabled?.();
  }, [breadcrumb?.enable, breadcrumbsInterface]);

  const [menuExpanded, setMenuExpanded] = useState(false);
  const handleToggle = (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean
  ) => {
    return setMenuExpanded(expanded);
  };

  const handleClickSideMenuItem = () => {
    return setMenuExpanded(false);
  };
  const sideMenuList: MenuType[] = useMemo(() => {
    const transformRoutesToMenu = (thisRoutes: RouteType[]) => {
      if (!Array.isArray(thisRoutes)) return;

      const result: T.HeaderLeadingAreaPropsType['menuList'] = [];

      // This could be in utils file
      thisRoutes.forEach((route) => {
        if (route?.label) {
          result.push({
            text: {
              value: route?.label,
              translationKey: route?.translationKey
            },
            criterionKey: route?.criterionKey,
            iconReference: route?.iconReference,
            path: route?.path,
            subMenus: transformRoutesToMenu(route?.subRoutes),
            exact: route?.exact,
            routeKey: route?.key
          });
          if (route?.iconReference) setIsIconReferenceNotEmpty(true);
        }
      });

      return result;
    };

    if (Array.isArray(menuList)) {
      const recursiveMenuListMap = (thisMenus: MenuType[]): MenuType[] => {
        if (!Array.isArray(thisMenus)) return;
        return thisMenus.map((menu) => {
          const route = menu?.routeKey && routes.findRouteByKey(menu?.routeKey);

          if (menu?.iconReference || route?.iconReference)
            setIsIconReferenceNotEmpty(true);

          return {
            criterionKey: menu?.criterionKey || route?.criterionKey,
            iconReference: menu?.iconReference || route?.iconReference,
            path: menu?.path || route?.path,
            subMenus:
              recursiveMenuListMap(menu?.subMenus) ||
              transformRoutesToMenu(route?.subRoutes),
            text: {
              value: menu?.text?.value || route?.label,
              translationKey:
                menu?.text?.translationKey || route?.translationKey
            }
          };
        });
      };

      return recursiveMenuListMap(menuList);
    } else {
      return transformRoutesToMenu(routes.getRoutes());
    }
  }, [menuList, routes]);

  return (
    <S.Container headerHeight={headerHeight}>
      <S.SideMenu
        responsiveBreakpoint={responsiveBreakpoint}
        size={size}
        expanded={menuExpanded}
        onToggle={handleToggle}
        appearance={currentAppearance}
        headerHeight={headerHeight}
        data-testid={createId(`side-menu`)}
        position="fixed"
        //@ts-ignore talk to Veneer to allow elements here
        appName={
          <WidgetFactory
            criterionKey={appName?.criterionKey}
            appName={appName}
          />
        }
        logo={
          <WidgetFactory
            logo={logo}
            image={image}
          />
        }
        showIcons={isIconReferenceNotEmpty}
      >
        {sideMenuList?.map?.((menu, index) => (
          <RecursiveMenuItemLoader
            data-testid={createId(`side_menu_item_${index}`)}
            key={`${index}`}
            onClick={handleClickSideMenuItem}
            {...menu}
          />
        ))}
        {(iconsOnlyDisabled || menuExpanded) && hasWidgets && (
          <S.WidgetContainer data-testid={createId(`WidgetContainer`)}>
            {widgetList?.map?.(({ key, ...props }) => (
              <WidgetFactory
                {...props}
                data-testid={createId(`side_menu_item_${key}`)}
                key={key}
              />
            ))}
          </S.WidgetContainer>
        )}
      </S.SideMenu>
      {isBreadcrumbEnabled && (
        <S.BreadcrumbWrapper
          iconsOnly={!!iconsOnly?.enable}
          responsiveBreakpoint={responsiveBreakpoint}
          isRTL={isRTL}
        >
          <Breadcrumbs
            responsiveBreakpoint={responsiveBreakpoint}
            data-testid={createId(`Breadcrumbs`)}
          />
        </S.BreadcrumbWrapper>
      )}
    </S.Container>
  );
};

export default HeaderLeadingArea;
