import primitives from '@veneer/primitives';
import styled from 'styled-components';
import * as T from './types';

type CustomStyleType = {
  customStyles: T.RegularLayoutPropsType['properties']['customStyle'];
};

export const Container = styled.div<CustomStyleType>`
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  transition: 500ms padding;
  overflow: hidden;
  background: ${({ customStyles }) =>
    customStyles?.backgroundColor || primitives.color.gray0};

  & > header {
    padding: 0 25px;
  }
  p {
    color: ${primitives.color.gray10};
  }
  a {
    text-decoration: none;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ChildrenWrapper = styled.div``;
