import styled from 'styled-components';

const maxContentWidth = '1280px';
const maxTabletWidth = 1279;
const maxPhoneWidth = 767;

type ContainerPropsType = { fullWidth: boolean };

export const Container = styled.div<ContainerPropsType>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : maxContentWidth)};
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: nowrap;

  @media (max-width: ${maxPhoneWidth}px) {
    flex-direction: column;
  }
`;

const widerLeftContent = 343;
const smallerLeftContent = 192;

export const LeftContent = styled.div`
  height: 100%;
  width: ${widerLeftContent}px;
  min-width: ${widerLeftContent}px;
  max-width: ${widerLeftContent}px;

  @media (max-width: ${maxTabletWidth}px) {
    width: ${smallerLeftContent}px;
    min-width: ${smallerLeftContent}px;
    max-width: ${smallerLeftContent}px;
  }
  @media (max-width: ${maxPhoneWidth}px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
`;

export const RightContent = styled.div`
  height: 100%;
  width: 100%;
  max-width: 100%;
`;
