import React from 'react';
import SideMenuLayout from './layouts/SideMenuLayout';
import RegularLayout from './layouts/RegularLayout';
import {
  RegularLayoutPropertiesType,
  ShellRootComponentProps,
  SideMenuLayoutPropertiesType
} from './types/shell';

const App = ({ properties, ...props }: ShellRootComponentProps) => {
  if (properties.layoutType === 'regular') {
    return (
      <RegularLayout properties={properties as RegularLayoutPropertiesType}>
        {props.children}
      </RegularLayout>
    );
  }

  return (
    <SideMenuLayout properties={properties as SideMenuLayoutPropertiesType}>
      {props.children}
    </SideMenuLayout>
  );
};

export default App;
